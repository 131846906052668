import { Component, OnInit } from '@angular/core';
import { contactSales } from 'src/app/helpers/whatsapp.redirector';

declare var gtag: any;

@Component({
  selector: 'app-popup-deals',
  templateUrl: './popup-deals.component.html',
  styleUrls: ['./popup-deals.component.scss']
})

export class PopupDealsComponent implements OnInit {

  isShowing: boolean = false;
  currentMs = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
    this.getAdsSettings();
  }

  getAdsSettings(): void {
    const adsSettings = localStorage.getItem("jejakimani-popup-deals");
    
    if (!adsSettings || adsSettings == null) {
      localStorage.setItem("jejakimani-popup-deals", '');
      this.onIdleStart();
    } else {
      const apr = new Date("2025-03-31").getTime();
      if ((this.currentMs > (parseInt(adsSettings, 10) + 1800000)) && (this.currentMs < apr)) {
        this.onIdleStart();
      }
    }
  }

  onIdleStart(): void {
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
      this.isShowing = true;
    }, 15000);
  }

  onClosePopupHandler(): void {
    document.body.style.overflow = 'auto';
    this.isShowing = false;
    const timestamp = new Date().getTime();
    localStorage.setItem("jejakimani-popup-deals", String(timestamp));
  }

  onClickWhatsapp = async (): Promise<void> => {
    const response = await contactSales('/whatsapp_Deals');

    if (response) {
        const { whatsapp_api, whatsapp_number, google_tag_event, google_tag_event_label, google_tag_event_category } = response;
        var target = whatsapp_api ?? `https://api.whatsapp.com/send/?phone=${whatsapp_number}`;
        gtag('event', google_tag_event, {
            'page_path': window.location.href,
            'event_category': google_tag_event_category,
            'event_label': google_tag_event_label
        });
        if (!window.open(target)) {
          window.location.href = target;
        }
        // window.open(target);
    }
  }

}
