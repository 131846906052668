<div class="cst-selector-wrapper" [class.active]="isOpen" >
    <label>{{ label }}</label>
    <button type="button" (click)="onStartSelection()">
        <span>
            <span class="font-ji" *ngIf="addJiPrefix">jejak imani </span>
            {{ value$ | async }}
        </span>
        <i class="fas fa-chevron-down"></i>
    </button>
    <div class="cst-selector-inner">
        <a href="#" (click)="onSelectAction($event, opt.value)" *ngFor="let opt of options" [class.selected]="(value$ | async) == opt.label">
            <span class="font-ji" *ngIf="addJiPrefix">jejak imani</span> {{ opt.label }}
        </a>
    </div>
</div>
