import { environment } from "src/environments/environment"

export async function contactSales(pageName: string) {
    const waKey = pageName.split("_")[1];

    const params = new URLSearchParams({
        'pageName': waKey,
    });

    const whatsappResp = await fetch(`${environment.apiUrl}contact-sales?${params.toString()}`);
    if (whatsappResp.ok) return whatsappResp.json();
    return undefined;
}