import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-custom-selector',
  templateUrl: './custom-selector.component.html',
  styleUrls: ['./custom-selector.component.scss']
})
export class CustomSelectorComponent implements OnInit {
  private currentValue = new BehaviorSubject<string>('');
  value$ = this.currentValue.asObservable();

  @Input() label: string;
  @Input() options: any[];
  @Input() addJiPrefix: boolean = false;
  @Input() set value(val: string) {
    this.currentValue.next(val);
  }
  @Output() valueChange = new EventEmitter<string>();

  isOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  onStartSelection(): void {
    this.isOpen = !this.isOpen;
  }

  onSelectAction(e: Event, value: any) {
    e.preventDefault();
    this.valueChange.emit(value);
    this.isOpen = false;
  }
}
