import { Component, OnInit } from '@angular/core';
import { contactSales } from 'src/app/helpers/whatsapp.redirector';
import { FabService } from 'src/app/services/fab.service';

declare var gtag: any;

@Component({
  selector: 'app-floating-whatsapp-button',
  templateUrl: './floating-whatsapp-button.component.html',
  styleUrls: ['./floating-whatsapp-button.component.scss']
})

export class FloatingWhatsappButtonComponent implements OnInit {
  isShow$ = this.fabService.isShow$;
  isCaptionShow = false;

  constructor(
    private fabService: FabService,
  ) { 
    
  }

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 800) {
        this.isCaptionShow = true;
      } else {
        this.isCaptionShow = false;
      }
    });
  }

  onClickWhatsapp = async (): Promise<void> => {
    const response = await contactSales('/whatsapp_Tombol Daftar');

    if (response) {
        const { whatsapp_api, whatsapp_number, google_tag_event, google_tag_event_label, google_tag_event_category } = response;
        var target = whatsapp_api ?? `https://api.whatsapp.com/send/?phone=${whatsapp_number}`;
        gtag('event', google_tag_event, {
            'page_path': window.location.href,
            'event_category': google_tag_event_category,
            'event_label': google_tag_event_label
        });
        
        if (!window.open(target)) {
          window.location.href = target;
        }
    }
  }
}
