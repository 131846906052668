import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

interface JIUserConfig {
  selectedLocation: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /** TURN THIS TO TRUE TO ENABLE POPUP SELECTION */
  enableRedirection: boolean = false;
  /** POPUP CONTROLLER */
  showPopupLocation: boolean = false;
  selectedLocation: string = 'Jakarta';

  /** LOCATION SELECT OPTION */
  listLocation = [
    {
      url: 'https://jejakimani.com',
      value: 'jakarta',
      label: 'Jakarta'
    },{
      url: 'https://jogja.jejakimani.com',
      value: 'yogyakarta',
      label: 'Yogyakarta'
    },{
      url: 'https://palembang.jejakimani.com',
      value: 'palembang',
      label: 'Palembang'
    },{
      url: 'https://surabaya.jejakimani.com',
      value: 'surabaya',
      label: 'Surabaya'
    },{
      url: 'https://bandung.jejakimani.com',
      value: 'bandung',
      label: 'Bandung'
    },
  ];

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.readUserConfiguration();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }

      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0)
      }
    });
  }

  /** CHECK IF USER HAS LOCATION CONFIGURATION ALREADY */
  readUserConfiguration(): void {
    const userSetting = localStorage.getItem("jejakimani-userconfig");
    /**
     * KONDISI KETIKA USER CONFIGURATION BELUM ADA (user pertama kali membuka jejakimani.com), maka pop-up lokasi akan ditampilkan
     * APABILA USER SUDAH PERNAH MENGISI POPUP, MAKA AKAN DI HIDDEN
     */
    if (!userSetting || userSetting == null) this.showPopupLocation = true;
  }

  /**
   * FUNCTION UNTUK HANDLER KETIKA USER MEMILIH VALUE DARI DROPDOWN
   * @param loc : string lokasi yang dipilih user
   */
  onSelectLocationHandler(loc: string): void {
    const current = this.listLocation.find(l => l.value === loc);
    if (current) {
      this.selectedLocation = current.label;
    }
  }

  /**
   * FUNCTION KETIKA USER MELAKUKAN KONFIRMASI SETELAH MEMILIH LOKASI
   */
  onConfirmPopupAction(): void {
    const selected = this.listLocation.find(l => l.label === this.selectedLocation);
    // HANDLER KETIKA USER MEMILIH JAKARTA (PUSAT) ATAU TERJADI ERROR MISMATCH
    if (!selected || this.selectedLocation === 'Jakarta') this.showPopupLocation = false;
    else {
      // MEMBUAT USERCONFIG BARU UNTUK ME-REPLACE LOCAL STORAGE (popup lokasi tidak akan tampil kembali hingga user melakukan clear cache)
      const cfg: JIUserConfig = {
        selectedLocation: this.selectedLocation
      }
      localStorage.setItem("jejakimani-userconfig", JSON.stringify(cfg));
      // REDIRECT USER PADA SUB-DOMAIN SESUAI DENGAN LOKASI YANG DIPILIH
      window.location.replace(selected.url);
    }
  }

  /**
   * FUNCTION KETIKA USER MENEKAN CLOSE PADA POPUP
   */
  onCancelPopupAction(): void {
    this.showPopupLocation = false;
  }
}
