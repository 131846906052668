import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class FabService {
    private isShow = new BehaviorSubject<boolean>(true);
    public readonly isShow$ = this.isShow.asObservable();
  
    setIsShow(showState: boolean) {
        this.isShow.next(showState);
    }
}