<div id="global-loader-wrapper" *ngIf="message$ | async as loader">
    <div class="global-loader-body">
        <!-- <div class="spiner"></div>
        <div class="spiner2"></div>
        <div class="spiner3"></div> -->
        <div class="img-holder">
            <img src="/assets/img/logo-loader.png" alt="loader" />
        </div>
        <label>{{ loader }}</label>
    </div>
</div>
